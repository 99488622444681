import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import styles from "./Countdown.module.scss";
import moment from "moment";

type CountdownProps = {
  type?: "custom";
  claimTime: moment.Moment;
  useFormat?: boolean;
  suffix?: string;
  onTimeOver: (time: number) => void;
};

const timeHeaders = ["days", "hours", "minutes", "seconds"];

const Countdown = ({
  type,
  claimTime,
  useFormat = true,
  suffix,
  onTimeOver,
}: CountdownProps) => {
  const moment = require("moment");
  const [time, setTime] = useState(
    moment.duration(moment.utc(claimTime).diff(moment.utc()), "milliseconds")
  );
  const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (time === "over") return;
    if (time.asSeconds() < 0) {
      if (onTimeOver) {
        setTime("over");
        onTimeOver(time.asSeconds());
      }
      return;
    }

    intervalRef.current = setTimeout(() => {
      setTime((prevTime: moment.Moment) =>
        prevTime.clone().subtract(1000, "milliseconds")
      );
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    //eslint-disable-next-line
  }, [time]);

  useEffect(() => {
    const _claimTime = moment.duration(
      moment(claimTime).diff(moment.utc()),
      "milliseconds"
    );
    if (time !== "over" || _claimTime.asSeconds() < 0) return;
    setTime(
      moment.duration(moment(claimTime).diff(moment.utc()), "milliseconds")
    );
    //eslint-disable-next-line
  }, [claimTime, time]);

  const renderTimerView = () => {
    if (time === "over") return `00:00:00:00`;
    if (useFormat) {
      if (type === "custom") {
        const timeData = `${time.days() < 10 ? "0" : ''}${time.days()}:${moment
          .utc(time.as("milliseconds"))
          .format("HH:mm:ss")}`;

        return (
          <div className={styles.timerCustomComponent}>
            {timeData.split(":").map((_t, index) => (
              <Fragment key={index}>
                {index !== 0 && (
                  <span className={styles.splitter}>:</span>
                )}
                <span className={styles.timeBlock}>
                  <span className="font-mattone">{_t}</span>
                  <small className="font-menagrotesk">{timeHeaders[index]}</small>
                </span>
              </Fragment>
            ))}
          </div>
        );
      }

      const days = Math.floor(time.asDays());
      const hours = Math.floor((time.asDays() % 1) * 24);

      if (days >= 1) return `${days}d ${hours}h`;
      return hours > 0
        ? `${time.hours()}h ${time.minutes()}m`
        : time.minutes() > 0
        ? `${time.minutes()} minutes`
        : `${time.seconds()} seconds`;
    }
    return time;
  };

  return <>
    {suffix && <span className={styles.suffix}>{suffix}</span>}
    {renderTimerView()}
  </>;
};

export default Countdown;
