import axios from "axios";

export type NftResult = {
  total: number;
  result: [{
    token_id: string
  }];
  status: string
}

export async function fetchOwnerBatIds(address: string) {
  const { data } = await axios.get<NftResult>(
    `/api/bat_ids/${address}`
  );
  return data;
}

export async function fetchPresalePhase1LeafAndProof(address: string) {
  const { data } = await axios.get<{ leaf: any; proof: string[] }>(
    `/api/presale-phase1-merkle-proof/${address}`
  );
  return data;
}
