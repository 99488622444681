import React, { useState } from "react";
import ConnectButton from "components/ConnectButton/ConnectButton";
import { useWeb3Context } from "components/Layout";
import moment from "moment";
import {
  PRE_SALE_PHASE1_END_TIME,
  SALE_STAGE,
  T_SALE_STAGE,
} from "utils/config";
import Countdown from "./Countdown/Countdown";
import WatchGif from "assets/images/watch.gif";
import Image from "next/image";
import { formatWatches } from "utils/utilities";

type Props = {
  countDownIsOver: boolean | null;
  setCountdownIsOver: React.Dispatch<React.SetStateAction<boolean | null>>;
  balance?: number;
  overrideSaleStage?: T_SALE_STAGE;
  overridePhase1Timer?: number;
};

export default function MainContent({
  countDownIsOver,
  setCountdownIsOver,
  balance,
  overrideSaleStage,
  overridePhase1Timer,
}: Props) {
  const _SALE_STAGE = overrideSaleStage ?? SALE_STAGE;
  const _PRE_SALE_PHASE1_END_TIME =
    overridePhase1Timer ?? PRE_SALE_PHASE1_END_TIME;

  const { address } = useWeb3Context();
  const [claimTime] = useState(moment.utc(_PRE_SALE_PHASE1_END_TIME));

  if (_SALE_STAGE === T_SALE_STAGE.CLOSED) {
    return (
      <div className="mt-24 text-center px-2 font-menagrotesk">
        <p>The mint is not live yet.</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="relative flex justify-center w-[22rem] h-[22rem] z-0 mt-14 mb-4">
          <Image src={WatchGif} objectFit="fill" alt="watch" />
        </div>
      </div>

      <h1 className="text-3xl sm:text-4xl text-center px-6">
        {countDownIsOver
          ? SALE_STAGE === T_SALE_STAGE.PUBLIC
            ? "Welcome to the Z3NA public mint!"
            : null
          : "Welcome to the Z3NA access list mint!"}
        <br />
        {balance !== undefined && Number(balance) > 0 && (
          <small className="m-0 text-center text-xl">
            You own <b>{balance}</b> Z3NA {formatWatches(balance)}
          </small>
        )}
      </h1>

      {!countDownIsOver && (
        <>
          <p className="text-center mt-2 mb-6 sm:mb-4 sm:mt-6 font-menagrotesk">
            Presale mint ends in:
          </p>
          <Countdown
            type="custom"
            claimTime={claimTime}
            onTimeOver={() => setCountdownIsOver(true)}
          />
        </>
      )}

      {!address && (
        <div className="mt-6 w-full text-center px-6">
          <ConnectButton />
        </div>
      )}
    </>
  );
}
