import { BigNumber, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { serializeError } from "eth-rpc-errors";
import Button from "../Button/Button";
import { NotificationInterface } from "../Notification/Notification";
import {
  asyncFilter,
  formatWatches,
  getUserError,
  showTransactionNotifications,
} from "utils/utilities";
import { ContractTypes, MINT_PRICE } from "utils/contracts";
import Dropdown from "components/Dropdown/Dropdown";
import { useContract } from "utils/web3";
import Spinner from "components/Spinner/Spinner";
import { useWeb3Context } from "components/Layout";
import {
  EXTRA_PRESALE_MINTS_PER_AMBASSADOR_TOKEN,
  MAX_MINT_PRESALE,
  MAX_MINT_PUBLIC,
  NETWORK,
  T_SALE_STAGE,
} from "utils/config";
import {
  fetchOwnerBatIds,
  fetchPresalePhase1LeafAndProof,
  NftResult,
} from "utils/apis";
import classNames from "classnames";

interface Props {
  saleStage: T_SALE_STAGE;
  address: string;
  countDownIsOver: boolean | null;
  setCountdownIsOver: React.Dispatch<React.SetStateAction<boolean | null>>;
  balance?: number;
  setBalance: React.Dispatch<React.SetStateAction<number | undefined>>;
  setNotification: (
    props: NotificationInterface
  ) => React.Dispatch<React.SetStateAction<NotificationInterface | undefined>>;
}

function Mint({
  saleStage,
  address,
  countDownIsOver,
  setCountdownIsOver,
  balance,
  setBalance,
  setNotification,
}: Props) {
  const [z3naNftContract] = useContract(ContractTypes.Z3NA_NFT_CONTRACT);
  const { isProcessing, setIsProcessing } = useWeb3Context();
  const [whitelistProof, setWhitelistProof] = useState<string[]>();
  const [selected, setSelected] = useState<string>("1");
  const [etherScanLink, setEtherScanLink] = useState<string>("");
  const [ambassadorData, setAmbassadorData] = useState<NftResult>();
  const [unusedBatTokenList, setUnusedBatTokenList] = useState<
    string[] | undefined
  >(undefined);
  const [mintPrice, setMintPrice] = useState<number>(MINT_PRICE);
  const parsedUnusedBatTokens =
    unusedBatTokenList === undefined ? [] : unusedBatTokenList;
  const combinedMintPrice = Math.max(
    (mintPrice * 10000 * (Number(selected) - parsedUnusedBatTokens.length)) /
      10000,
    0
  ); // fix bad js number formatter by multiply by 10000

  const alreadyUsedTokens = ambassadorData?.result
    .map(({ token_id }) => token_id)
    ?.filter((token_id) => !unusedBatTokenList?.includes(token_id));

  useEffect(() => {
    if (!z3naNftContract || !address) return;

    const getMintPrice = async () => {
      try {
        const _mintPrice = (await z3naNftContract.mintPrice()) as BigNumber;
        setMintPrice(Number(ethers.utils.formatEther(_mintPrice)));
      } catch (error) {
        console.log(error);
      }
    };

    getMintPrice();
  }, [address, z3naNftContract]);

  useEffect(() => {
    if (!address) return;
    const fetchNFTsForContract = async () => {
      const polygonNFTs = await fetchOwnerBatIds(address);
      setAmbassadorData(polygonNFTs);
    };

    fetchNFTsForContract();
  }, [address]);

  useEffect(() => {
    if (!z3naNftContract) return;

    if (ambassadorData?.total && Number(ambassadorData.total) > 0) {
      const checkTokensClaimed = async () => {
        const unClaimedList = ambassadorData.result?.map(
          ({ token_id }) => token_id
        );

        if (unClaimedList) {
          const unClaimedTokenIds = await asyncFilter<string>(
            unClaimedList,
            async (tokenId: string) =>
              !(await z3naNftContract.hasAmbassadorTokenClaimed(tokenId))
          );

          setUnusedBatTokenList(unClaimedTokenIds);
        }
      };
      checkTokensClaimed();
    } else {
      setUnusedBatTokenList([]);
    }
  }, [
    ambassadorData?.result,
    ambassadorData?.total,
    saleStage,
    z3naNftContract,
  ]);

  useEffect(() => {
    if (!z3naNftContract || !address) return;

    const fetchInitialData = async () => {
      try {
        const [{ proof }, balance] = await Promise.all([
          await fetchPresalePhase1LeafAndProof(address),
          await z3naNftContract.balanceOf(address),
        ]);
        setWhitelistProof(proof);
        setBalance(balance.toNumber());
      } catch (error) {
        console.error(error);
        setWhitelistProof([]);
      }
    };

    fetchInitialData();

    return () => {
      setCountdownIsOver(null);
      setWhitelistProof([]);
    };
  }, [
    address,
    z3naNftContract,
    setBalance,
    setCountdownIsOver,
    setWhitelistProof,
    saleStage,
  ]);

  const handleMint = async (amount: string) => {
    if (!z3naNftContract) return;

    let transaction;
    try {
      if (countDownIsOver) {
        // public sale
        transaction = await z3naNftContract.mint(amount, unusedBatTokenList, {
          from: address,
          value: ethers.utils.parseEther(combinedMintPrice.toString()),
        });
      } else if (saleStage === T_SALE_STAGE.PHASE1) {
        // const filterBatTokenToUseByAmount = unusedBatTokenList?.filter(
        //   (i, index) => index === 0 || Number(amount) >= (index) * EXTRA_PRESALE_MINTS_PER_AMBASSADOR_TOKEN
        // );

        transaction = await z3naNftContract.mintPresale(
          amount,
          whitelistProof,
          unusedBatTokenList,
          {
            from: address,
            value: ethers.utils.parseEther(combinedMintPrice.toString()),
          }
        );
      }

      await showTransactionNotifications(
        setNotification,
        setIsProcessing,
        transaction
      );

      setEtherScanLink(`https://etherscan.io/tx/${transaction.hash}`);
      setBalance(Number(balance) + Number(amount));
    } catch (e: Error | unknown | any) {
      const error: any = serializeError(e);
      console.error(error);
      const message =
        error?.data?.message ||
        error?.data?.originalError?.error?.message ||
        "An error has occured";

      if (e?.code === "INSUFFICIENT_FUNDS") {
        setNotification({
          type: "error",
          title: "Error",
          message: `You don't have enough eth to mint`,
        });
      } else if (e?.code === 4001) {
        setNotification({
          type: "error",
          title: "Error",
          message: `Transaction rejected by user`,
        });
      } else {
        setNotification({
          type: "error",
          title: "Error",
          message: getUserError(message),
        });
      }
    }
  };
  const getDropdownOptions = () => {
    const fillDropdown = (arr: Array<number>) =>
      arr.fill(1).map((x: number, y: number) => x + y);

    switch (saleStage) {
      case "PHASE1": {
        const presaleDropdown =
          whitelistProof !== undefined && whitelistProof.length > 0
            ? Array(
                parsedUnusedBatTokens.length *
                  EXTRA_PRESALE_MINTS_PER_AMBASSADOR_TOKEN
              ).concat(Array(MAX_MINT_PRESALE)) // is in whitelist + hold bat
            : Array(
                parsedUnusedBatTokens.length *
                  EXTRA_PRESALE_MINTS_PER_AMBASSADOR_TOKEN
              ); // not in whitelist but is bat holder

        return fillDropdown(presaleDropdown);
      }

      default:
        return fillDropdown(
          Array(
            parsedUnusedBatTokens.length *
              EXTRA_PRESALE_MINTS_PER_AMBASSADOR_TOKEN
          ).concat(Array(MAX_MINT_PUBLIC))
        );
    }
  };

  return (
    <div className="flex flex-col align-center justify-center mt-6 px-6">
      {whitelistProof !== undefined &&
        alreadyUsedTokens !== undefined &&
        whitelistProof.length === 0 &&
        parsedUnusedBatTokens.length === 0 &&
        alreadyUsedTokens.length === 0 &&
        !countDownIsOver && (
          <>
            <p className="text-center text-xl text-red-500">
              Sorry, but it seems that you aren&apos;t on the mint list.
            </p>
            <p className="text-center text-xl leading-8 text-red-500">
              You can still mint Z3NA watches in the public mint.
            </p>
          </>
        )}

      {whitelistProof === undefined ||
      unusedBatTokenList === undefined ||
      alreadyUsedTokens === undefined ? (
        <Spinner />
      ) : (
        (((whitelistProof.length > 0 || unusedBatTokenList.length > 0) &&
          !countDownIsOver) ||
          countDownIsOver) && (
          <>
            {unusedBatTokenList.length > 0 && (
              <>
                <p
                  className={classNames({
                    "text-center text-xl px-6 text-[#b44aff]": true,
                    "mb-4":
                      alreadyUsedTokens !== undefined &&
                      alreadyUsedTokens.length === 0,
                  })}
                >
                  Congrats! <br />
                  As a Brandverse Ambassador, you qualify for{" "}
                  {unusedBatTokenList.length} FREE Z3NA{" "}
                  {formatWatches(unusedBatTokenList.length)}!
                </p>

                {alreadyUsedTokens !== undefined &&
                  alreadyUsedTokens.length > 0 && (
                    <p className="text-sm mt-2 mb-6">
                      BAT ids: {alreadyUsedTokens?.join(",")} has already used
                      for claim.
                    </p>
                  )}
              </>
            )}

            <p className="text-center text-xl px-6">
              Please select the amount you&apos;d like to mint
            </p>

            <Dropdown
              options={getDropdownOptions()}
              selected={selected}
              setSelected={setSelected}
            />

            <Button
              disabled={isProcessing}
              buttonType="ActionButton"
              className="w-full mt-6 text-xl max-w-xs mx-auto whitespace-nowrap"
              onClick={() => handleMint(selected.toString())}
            >
              MINT - {combinedMintPrice} ETH
            </Button>

            {etherScanLink && (
              <a
                className="relative top-8 hover:underline"
                href={etherScanLink}
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                View on etherscan
              </a>
            )}
          </>
        )
      )}
    </div>
  );
}

export default Mint;
